<div class="fixed-container" #scrollFrame>
  <div>
    <div class="punch-patient-report-wrapper">
      <div class="kells-report">
        <!-- Top navigation bar -->
        <section class="hero-section report-generate-block">
          <div class="kells-report-container">
            <div class="hero-section-conatiner">
              <div
                class="nav-bar-non-sticky flex justify-between items-center py-5"
              >
                <a [href]="'/'" class="flex kells-logo-link">
                  <div class="xs:flex xs:h-auto">
                    <img [src]="REPORT_LOGO" alt="kells-logo" />
                  </div>
                </a>
                <a
                  target="_blank"
                  (click)="onGetReportClick()"
                  href="https://www.getkells.com/ai-dental-scan"
                  class="homepage-link"
                >
                  Get my KELLS report
                </a>
              </div>

              <div class="flex justify-between items-center py-5 page-title">
                <div>
                  <h1 class="fw-700 uppercase">Kells Oral Health Report</h1>
                </div>
              </div>

              <div class="flex justify-between py-10 items-center">
                <div class="user-profile flex flex-row items-center">
                  <div class="user-thumbnil">
                    <span class="ft-large">OS</span>
                  </div>
                  <div class="user-name ml-5">
                    <p class="ft-large fw-700">Olivia Smith</p>
                    <p class="ft-small ft-500">olivia@smith.com</p>
                  </div>
                </div>

                <div class="report-date">
                  <p class="ft-small fw-700 grey-label">REPORT DATE</p>
                  <p class="ft-small fw-500">
                    {{ formatDateString(sessionDate) }}
                  </p>
                </div>
              </div>

              <div class="disclaimer my-3">
                <p class="disclaimer-content p-3 ft-small">
                  This report is based solely on the evaluation of your dental
                  images. The results are generated through a combination of AI
                  technology and a licensed dentist's review. Please note that
                  this report is not intended to serve as a formal diagnosis or
                  replace an in-person visit to a dental office.
                </p>
              </div>
            </div>
          </div>
        </section>
        <!-- Top navigation bar end -->

        <!-- Sticky bar -->
        <section
          #pageHeader
          class="sticky-bar page-header report-generate-block"
        >
          <div class="navbar w-nav shadow-md sticky top-0 z-50">
            <div class="kells-report-container flex justify-center">
              <nav
                role="navigation"
                class="nav-menu nav-menu grid xs:grid-cols-5 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 2xl:grid-cols-5"
              >
                <a
                  (click)="scrollToSection(SNAPSHOT_SECTION_ID)"
                  class="nav-link"
                  [ngClass]="{
                    active: currentSection === SNAPSHOT_SECTION_ID
                  }"
                >
                  <span>SNAPSHOT</span>
                  <span class="underline"></span>
                </a>

                <a
                  (click)="scrollToSection(CAVITIES_SECTION_ID)"
                  class="nav-link-text nav-link"
                  [ngClass]="{
                    active: currentSection === CAVITIES_SECTION_ID
                  }"
                >
                  <span>YOUR TEETH</span>
                  <span class="underline"></span>
                </a>

                <a
                  class="nav-link"
                  (click)="scrollToSection(SMILE_CHECKLIST_SECTION_ID)"
                  [ngClass]="{
                    active: currentSection === SMILE_CHECKLIST_SECTION_ID
                  }"
                >
                  <span>SMILE CHECKLIST</span>
                  <span class="underline"></span>
                </a>

                <a
                  class="nav-link"
                  (click)="scrollToSection(PRODUCT_RECOMMENDATION_SECTION_ID)"
                  [ngClass]="{
                    active: currentSection === PRODUCT_RECOMMENDATION_SECTION_ID
                  }"
                >
                  <span>PRODUCT RECOMMENDATION</span>
                  <span class="underline"></span>
                </a>

                <a
                  class="nav-link"
                  *ngIf="isFindingDentistAvailable"
                  (click)="scrollToSection(FIND_A_DENTIST_SECTION_ID)"
                  [ngClass]="{
                    active: currentSection === FIND_A_DENTIST_SECTION_ID
                  }"
                >
                  <span>FIND A DENTIST</span>
                  <span class="underline"></span>
                </a>
              </nav>
              <div class="w-nav-button">
                <div class="w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </section>

        <!-- Snapshot section -->
        <section
          id="{{ SNAPSHOT_SECTION_ID }}"
          class="snapshot-section report-generate-block"
          #snapshot
        >
          <div class="kells-report-container">
            <h1 class="report-header mb-10 fw-700 bottom-hb">SNAPSHOT</h1>
            <div class="cavity-snapshot section-content">
              <div
                class="snapshot-row-layout grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2"
              >
                <div class="left-side">
                  <h3 class="snapshot-title pb-5 fw-700">OVERALL RISK</h3>
                  <h4 class="snapshot-subtitle py-2 fw-700">SUMMARY</h4>
                  <div
                    class="snapshot-description py-2 fw-500 ibmplex-semi-font ft-medium"
                  >
                    <p
                      class="snapshot-subhead"
                      [innerHTML]="snapshotSubhead"
                    ></p>
                  </div>
                </div>
                <div class="right-side relative">
                  <koa-oral-condition-donut-chart
                    [score]="sessionScore"
                  ></koa-oral-condition-donut-chart>
                </div>
              </div>
            </div>

            <div>
              <koa-xray-carousel
                title="YOUR INTRAORAL PHOTOS"
                [imageType]="SessionImageType.Photo"
                [showBoneloss]="false"
                [showCaries]="false"
                [showFindings]="false"
                [interactive]="true"
                [downloadEnabled]="false"
                [findingsByImage]="findingsByImage$ | async"
                (onXrayOpen)="(onXrayOpen)"
              >
              </koa-xray-carousel>
            </div>
          </div>
        </section>

        <!-- Cavity section -->
        <section
          class="cavities-section report-generate-block"
          id="{{ CAVITIES_SECTION_ID }}"
          #cavities
        >
          <ng-container
            *ngLet="{
              tooth: (activeTooth$ | async),
              stage: (activeToothCariesStage$ | async),
              ordn: (activeToothTitleOrdn$ | async),
              toothName: (activeToothTitle$ | async),
              diagnosis: (activeToothDiagnosis$ | async),
              activeTreatments: (toothTreatments$ | async),
              activeToothFindingsImages: (activeToothFindingsImages$ | async)
            } as tabsState"
          >
            <div class="kells-report-container">
              <div>
                <h1 class="report-header fw-700 mb-10 bottom-hb">YOUR TEETH</h1>
              </div>
            </div>

            <div class="multiple-cavities">
              <div class="kells-report-container">
                <div class="section-content">
                  <h3 class="report-header mb-5 fw-700">
                    YOU MAY HAVE MULTIPLE CONCERNS
                  </h3>
                  <koa-findings-filter
                    (selectedFindingsFilterTypeChanged)="
                      selectedFindingsFilterType.next($event)
                    "
                    [findingsFilterItems$]="findingsFilterItems$"
                  ></koa-findings-filter>
                  <div
                    id="cavitiesBody"
                    class="grid grid-cols-12 pt-8 gap-5 md:gap-3 xs:gap-1 sm:gap-1 xs:px-0"
                  >
                    <div
                      class="left-cavity-container 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-5 xs:col-span-12"
                    >
                      <div
                        class="caries-chart-legend w-full flex justify-center"
                      >
                        <div
                          class="flex items-center content-center mr-5 sm:mr-3"
                        >
                          <div class="legend-color initial mr-2 shadow"></div>
                          <div class="ft-medium">Initial</div>
                        </div>
                        <div
                          class="flex items-center content-center mr-5 sm:mr-3"
                        >
                          <div class="legend-color moderate mr-2 shadow"></div>
                          <div class="ft-medium">Moderate</div>
                        </div>
                        <div
                          class="flex items-center content-center mr-5 sm:mr-3"
                        >
                          <div class="legend-color advanced mr-2 shadow"></div>
                          <div class="ft-medium">Advanced</div>
                        </div>
                      </div>
                      <div
                        class="w-full xs:pb-5 mr-5 xs:mr-0 xs:pb-0 mouth-container relative"
                      >
                        <img
                          class="tooth-navigation-map"
                          src="/assets/punch/images/tooth-navigation.png"
                          alt="tooth-navigation"
                        />
                        <koa-mouth
                          [findingsByTooth]="findingsByTooth | async"
                          (activeToothChange)="setActiveCariesTooth($event)"
                          (toothClick)="recordToothClick($event)"
                          [selectedFindingsFilterType]="
                            selectedFindingsFilterType$ | async
                          "
                        ></koa-mouth>
                      </div>
                      <div
                        class="caries-chart-legend w-full flex justify-center"
                      >
                        <p class="ft-medium bg-white p-3 mt-5 fw-500">
                          Click on any colored tooth to learn more
                        </p>
                      </div>
                    </div>
                    <div
                      class="right-cavity-container ml-10 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-7 xs:col-span-12"
                    >
                      <div class="cavities-tab">
                        <div
                          data-current="Tab 1"
                          data-easing="ease"
                          data-duration-in="300"
                          data-duration-out="100"
                          class="w-tabs"
                        >
                          <div class="tab-menu flex">
                            <a
                              data-w-tab="Tab 1"
                              class="tab-item ft-large"
                              (click)="setActiveCariesTab(CARIES_TAB_FINDINGS)"
                              [ngClass]="{
                                active:
                                  (activeCariesTab$ | async) ===
                                  CARIES_TAB_FINDINGS
                              }"
                            >
                              Description <span class="underline"></span>
                            </a>

                            <a
                              data-w-tab="Tab 3"
                              class="tab-item ft-large"
                              (click)="
                                setActiveCariesTabXRay(CARIES_TAB_INTRAORAL)
                              "
                              [ngClass]="{
                                active:
                                  (activeCariesTab$ | async) ===
                                  CARIES_TAB_INTRAORAL
                              }"
                            >
                              Intraoral Photo
                              <span class="underline"></span>
                            </a>
                          </div>

                          <div class="tab-content">
                            <div class="tab-pane">
                              <div class="tab-container">
                                <div
                                  class="grid xs:grid-cols-12 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12"
                                >
                                  <div
                                    class="flex flex-col justify-start xs:col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 2xl:col-span-6"
                                  >
                                    <div class="tooth-illustration-wrapper">
                                      <img
                                        [src]="
                                          cariesToothIllustrationSvg$ | async
                                        "
                                        role="image"
                                        alt="{{ activeTooth.name }}"
                                        class="active-tooth-svg"
                                      />
                                    </div>

                                    <h1
                                      class="kpr-title dark-blue-label tooth-name lowercase pt-3"
                                    >
                                      <span class="text-ordinals">{{
                                        tabsState.ordn
                                      }}</span>
                                      {{ tabsState.toothName }}
                                    </h1>
                                  </div>

                                  <div
                                    *ngIf="
                                      tabsState.activeTreatments?.length &&
                                      (activeCariesTab$ | async) ===
                                        CARIES_TAB_FINDINGS
                                    "
                                    class="flex flex-col justify-start xs:col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 2xl:col-span-6"
                                  >
                                    <h3
                                      class="action-header ft-large fw-700 blue-light-label"
                                    >
                                      Potential Action
                                    </h3>

                                    <ng-container
                                      *ngFor="
                                        let trmnt of tabsState.activeTreatments
                                      "
                                    >
                                      <div
                                        class="treatment-plan flex flex-col my-5"
                                      >
                                        <h4
                                          class="treatment-plan-name mb-1 ft-large fw-500"
                                        >
                                          {{ trmnt.treatmentPlan }}
                                        </h4>

                                        <button
                                          class="video-button"
                                          *ngIf="hasVideo(trmnt.treatmentPlan)"
                                          (click)="
                                            openDialog(trmnt.treatmentPlan)
                                          "
                                        >
                                          <i class="fa-solid fa-play"></i>
                                          <span>Play video</span>
                                        </button>

                                        <h4
                                          *ngIf="
                                            trmnt.costEstimates &&
                                            trmnt.costEstimates != '-'
                                          "
                                          class="treatment-plan-cost grey-label ft-large fw-700"
                                        >
                                          {{ trmnt.costEstimates }}
                                          <i
                                            class="fa fas fa-question-circle"
                                            title="Estimated cost"
                                          ></i>
                                        </h4>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>

                                <div
                                  class="mt-5"
                                  data-w-tab="CARIES_TAB_FINDINGS"
                                  id="CARIES_TAB_FINDINGS"
                                  *ngIf="
                                    (activeCariesTab$ | async) ===
                                    CARIES_TAB_FINDINGS
                                  "
                                >
                                  <p
                                    class="tab-description-paragraph ft-medium dark-blue-label"
                                  >
                                    You may have issues with your
                                    <span class="text-ordinals">{{
                                      tabsState.ordn
                                    }}</span
                                    >{{ tabsState.toothName }} that you may want
                                    to be looked at by a dentist to be treated.
                                  </p>

                                  <ng-container
                                    *ngLet="
                                      activeToothFindingsDescriptions$
                                        | async as activeToothFindingsDescriptions
                                    "
                                  >
                                    <ng-container
                                      *ngFor="
                                        let findingDescription of activeToothFindingsDescriptions
                                      "
                                    >
                                      <div class="cavity-info-block">
                                        <h3
                                          class="moderate-cavity-header mb-5 fw-700 dark-blue-label"
                                          [innerHTML]="findingDescription.title"
                                        ></h3>
                                        <p
                                          class="moderate-cavity-paragraph ft-medium dark-blue-label"
                                          [innerHTML]="
                                            findingDescription.infoText
                                          "
                                        ></p>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </div>

                                <ng-container
                                  *ngLet="
                                    activeCariesTab$ | async as activeCariesTab
                                  "
                                >
                                  <div
                                    data-w-tab="Tab 2"
                                    class="w-tab-pane xray-tab"
                                    *ngIf="
                                      (activeCariesTab$ | async) ===
                                      CARIES_TAB_XRAY
                                    "
                                  >
                                    <koa-xray-tab
                                      [filterTooth]="
                                        (activeTooth$ | async).tooth_id
                                      "
                                      [xrays]="
                                        tabsState.activeToothFindingsImages
                                      "
                                      (onXrayOpen)="onXrayOpen()"
                                      [imageType]="SessionImageType.Xray"
                                      [hideIfNoFindingsForImage]="true"
                                      [findingsByImage]="
                                        findingsByImage$ | async
                                      "
                                    ></koa-xray-tab>
                                  </div>

                                  <div
                                    data-w-tab="Tab 2"
                                    class="w-tab-pane xray-tab"
                                    *ngIf="
                                      (activeCariesTab$ | async) ===
                                      CARIES_TAB_INTRAORAL
                                    "
                                  >
                                    <koa-xray-tab
                                      [filterTooth]="
                                        (activeTooth$ | async).tooth_id
                                      "
                                      [xrays]="
                                        tabsState.activeToothFindingsImages
                                      "
                                      (onXrayOpen)="onXrayOpen()"
                                      [imageType]="SessionImageType.Photo"
                                      [hideIfNoFindingsForImage]="true"
                                      [findingsByImage]="
                                        findingsByImage$ | async
                                      "
                                    ></koa-xray-tab>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="kells-report-container">
              <div class="potential-actions section-content">
                <h3 class="report-header mb-5 fw-700">POTENTIAL ACTIONS</h3>
                <koa-treatment-plan-table
                  [displayedColumns]="treamentPlanDisplayedTableColumns"
                  [images]="images"
                  [treatments]="treatments"
                >
                </koa-treatment-plan-table>
                <p class="ft-small fw-500 py-5">
                  Cost estimates are based on factors such as size, position in
                  mouth, and case complexity according to the 2020 American
                  Dental Association survey.
                </p>
              </div>

              <div class="cavities-xrays">
                <koa-xray-carousel
                  #xrayCarousel
                  [imageType]="SessionImageType.Photo"
                  [title]="'YOUR INTRAORAL PHOTOS'"
                  [showGumInflammation]="true"
                  [showMissingTooth]="true"
                  [showGumRecession]="true"
                  [showFracture]="true"
                  [showPlaque]="true"
                  [showBoneloss]="true"
                  [showCaries]="true"
                  [showFindings]="true"
                  [interactive]="true"
                  [hideIfNoFindingsForImage]="true"
                  [findingsByImage]="findingsByImage$ | async"
                  (onXrayOpen)="(onXrayOpen)"
                >
                </koa-xray-carousel>
              </div>
            </div>
          </ng-container>
        </section>

        <!--Bone health section -->
        <section #bonehealth id="{{ BONE_HEALTH_SECTION_ID }}"></section>

        <!-- Smile checklist -->
        <section
          id="{{ SMILE_CHECKLIST_SECTION_ID }}"
          class="smile-checklist report-generate-block"
          #smilechecklist
        >
          <div class="kells-report-container">
            <h1 class="report-header mb-5 fw-700 bottom-hb">
              YOUR SMILE CHECKLIST
            </h1>
            <div class="smile-checklist-details section-content">
              <p class="py-5 ft-medium fw-500">
                Some of the best practices to maintain your oral health are:
              </p>
              <div
                class="checklist flex xs:flex-wrap align-center justify-between"
              >
                <div
                  class="col-span-2 px-3 py-3 flex flex-col align-items-center justify-start items-center xs:w-1/2 xs:p-0 xs:my-3"
                >
                  <div
                    class="flex flex-col align-center content-end items-center w-auto"
                  >
                    <img
                      src="/assets/punch/images/btad.svg"
                      class="w-16 h-16 mb-3 m-auto"
                      alt="Tooth brush"
                    />
                    <p class="fw-500 ft-extra-small text-center">
                      Brush twice a day
                    </p>
                  </div>
                </div>
                <div
                  class="col-span-2 px-3 py-3 flex flex-col align-items-center justify-start items-center xs:w-1/2 xs:p-0 xs:my-3"
                >
                  <div
                    class="flex flex-col align-center content-end items-center w-auto"
                  >
                    <img
                      src="/assets/punch/images/SUGRY.svg"
                      class="w-16 h-16 mb-3 m-auto"
                      alt="Sugary foods"
                    />
                    <p class="fw-500 ft-extra-small text-center">
                      Limit sugary foods &amp; starches
                    </p>
                  </div>
                </div>
                <div
                  class="col-span-2 px-3 py-3 flex flex-col align-center items-center justify-start xs:w-1/2 xs:p-0 xs:my-3"
                >
                  <div
                    class="flex flex-col align-center items-center content-end w-auto"
                  >
                    <img
                      src="/assets/punch/images/umr.svg"
                      class="w-16 h-16 mb-3 m-auto"
                      alt="Mouthwash"
                    />
                    <p class="fw-500 ft-extra-small text-center">
                      Use mouthwash regularly
                    </p>
                  </div>
                </div>
                <div
                  class="col-span-2 px-3 py-3 flex flex-col align-center items-center justify-start xs:w-1/2 xs:p-0 xs:my-3"
                >
                  <div
                    class="flex flex-col align-center content-end items-center w-auto"
                  >
                    <img
                      src="/assets/punch/images/FLOSS.svg"
                      class="w-16 h-16 mb-3 m-auto"
                      alt="Dental Floss"
                    />
                    <p class="fw-500 ft-extra-small text-center">
                      Floss once a day
                    </p>
                  </div>
                </div>
                <div
                  class="col-span-2 px-3 py-3 flex flex-col align-center justify-start items-center xs:w-1/2 xs:p-0 xs:my-3"
                >
                  <div
                    class="flex flex-col align-center content-end items-center w-auto"
                  >
                    <img
                      src="/assets/punch/images/NO-SNOKING.svg"
                      class="w-16 h-16 mb-3 m-auto"
                      alt="Don't smoke"
                    />
                    <p class="fw-500 ft-extra-small text-center">
                      Avoid smoking
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Product recommendation section -->
        <section
          id="{{ PRODUCT_RECOMMENDATION_SECTION_ID }}"
          #productRecommendationSection
        >
          <kpr-product-recommendation-page
            class="product-recommendation-page"
            [isInreportRecommendation]="true"
          ></kpr-product-recommendation-page>
        </section>

        <!-- Find dentist section -->
        <section id="{{ FIND_A_DENTIST_SECTION_ID }}" #findDentistSection>
          <div
            *ngIf="isFindingDentistAvailable"
            class="find-dentist-section omit-report-generation"
          >
            <div class="kells-report-container">
              <h1 class="report-header mb-5 fw-700 bottom-hb">
                FIND A DENTIST
              </h1>
            </div>

            <div #searchWidgetContainer></div>
          </div>
        </section>

        <!-- Footer section -->
        <section id="footer" class="footer report-generate-block">
          <div class="footer-content">
            <kpr-footer [loading]="true"></kpr-footer>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
