import { RecommendedProductTabs } from "../enums/recommended-product.enum";
import { RecommendedProduct } from "../interfaces/recommended-product.interfaces";

export const recommendedProductsByCategory: Record<
  RecommendedProductTabs,
  RecommendedProduct[]
> = {
  [RecommendedProductTabs.Toothbrush]: [
    {
      name: "Oral B smart brush",
      link: "https://a.co/d/74Up9Kw",
      image: "assets/recommended-products/electric-toothbrush-oral-b.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
  ],
  [RecommendedProductTabs.Toothpaste]: [
    {
      name: "Fluoridated toothpaste",
      link: "https://a.co/d/eZu40BE",
      image: "assets/recommended-products/fluoridated-toothpaste.jpg",
      subCategory: ["General hygiene", "Cavity", "Xerostomia / dry mouth"],
    },
    {
      name: "Crest gum detoxify",
      link: "https://a.co/d/bjHFTiw",
      image: "assets/recommended-products/crest-gum-detoxity.jpg",
      subCategory: ["Gingivitis", "Periodontitis / bone loss"],
    },
    {
      name: "Sensodyne",
      link: "https://a.co/d/9WYVMpm",
      image: "assets/recommended-products/sensodyne.jpg",
      subCategory: ["Sensitive Teeth"],
    },
  ],
  [RecommendedProductTabs.Floss]: [
    {
      name: "Cocofloss",
      link: "https://amzn.to/4aOIdE8",
      image: "assets/recommended-products/cocofloss.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
  ],
  [RecommendedProductTabs.Mouthrinse]: [
    {
      name: "Listerine Total Care",
      link: "https://a.co/d/2WHHrAJ",
      image: "assets/recommended-products/listerine-total-care.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
        "Halitosis / bad breath",
      ],
    },
    {
      name: "Listerine gum care",
      link: "https://a.co/d/cLZOaxg",
      image: "assets/recommended-products/listerine-gum-care.jpg",
      subCategory: ["Gum Inflammation / Gingivitis"],
    },
    {
      name: "Biotene Dry Mouth rinse",
      link: "https://a.co/d/eJYT4uf",
      image: "assets/recommended-products/biotene-dry-mouth-rinse.jpg",
      subCategory: ["Xerostomia / dry mouth"],
    },
    {
      name: "Colgate Peroxyl and warm salt water rinses",
      link: "https://a.co/d/iNsB2UZ",
      image: "assets/recommended-products/colgate-peroxyl.jpg",
      subCategory: ["Canker sores/Aphthous Ulcers"],
    },
  ],
  [RecommendedProductTabs.Gel]: [
    {
      name: "Biotene Oral Balance Gel",
      link: "https://a.co/d/dpKKCJY",
      subCategory: ["Xerostomia / dry mouth"],
      image: "assets/recommended-products/biotene-dry-mouth-rinse.jpg",
    },
  ],
  [RecommendedProductTabs.TongueScraper]: [
    {
      name: "Tongue scraper",
      link: "https://a.co/d/iAp9Q3a",
      subCategory: ["Halitosis / bad breath"],
      image: "assets/recommended-products/tongue-scraper.jpg",
    },
  ],
};
