export type RiskLevel = "low" | "medium" | "high";

export const getSessionScoreDate = (sessionScore: number) => {
  const maxScore = 100;
  if (sessionScore > maxScore) {
    sessionScore = maxScore;
  } else if (sessionScore < 0) {
    sessionScore = 0;
  }

  let score = sessionScore > 0 ? (10 - sessionScore) * 10 : 100;

  // Round up sessionValue to edge location
  // If sessionScore value is less than 10 then round up value to 10
  // If sessionScore value is grater than 90 but less than 100 then round up value to 90
  // If sessionScore value is 100 - leave 100 (99.99 for better UI display)

  if (score <= 10) {
    score = 10;
  } else if (score >= 90 && score < 100) {
    score = 90;
  } else if (score == 100) {
    score = 99.9;
  }

  // Reverse score
  score = 100 - score;

  let statusLevel: RiskLevel | "" = "";
  // risk score diagram has next grades: 0-27 low, 27-72 medium, 72+ high
  let chartScore = score;
  if (score <= 30) {
    statusLevel = "low";

    if (score > 27) {
      chartScore = 27;
    }
  } else if (score > 30 && score < 80) {
    statusLevel = "medium";

    if (score > 72) {
      chartScore = 72;
    }
  } else if (score >= 80) {
    statusLevel = "high";
  }

  return {
    statusLevel,
    chartScore,
  };
};
