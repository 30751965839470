import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RecommendedProductTabs } from "../../shared/enums/recommended-product.enum";

@Component({
  selector: "kpr-recommended-product-navigation",
  templateUrl: "./recommended-product-navigation.component.html",
  styleUrls: ["./recommended-product-navigation.component.scss"],
})
export class RecommendedProductNavigationComponent {
  @Output() tabChange = new EventEmitter<RecommendedProductTabs>();
  @Input() set tabs(value: RecommendedProductTabs[]) {
    this._tabs = value;
    if (value?.length) this.selectTab(value[0]);
  }
  get tabs(): RecommendedProductTabs[] {
    return this._tabs;
  }
  _tabs: RecommendedProductTabs[];

  selectedTab: RecommendedProductTabs = RecommendedProductTabs.Toothbrush;

  selectTab(value: RecommendedProductTabs) {
    this.selectedTab = value;
    this.tabChange.emit(value);
  }
}
