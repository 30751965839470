import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RecommendedProductItemComponent } from "./components/recommended-product-item/recommended-product-item.component";
import { ProductRecommendationPageComponent } from "./containers/product-recommendation-page/product-recommendation-page.component";
import { RecommendedProductTabComponent } from "./components/recommended-product-tab/recommended-product-tab.component";
import { ProductRecommendationHeaderComponent } from "./components/product-recommendation-header/product-recommendation-header.component";
import { ProductRecommendationFooterComponent } from "./components/product-recommendation-footer/product-recommendation-footer.component";
import { ProductRecommendationMainComponent } from "./containers/product-recommendation-main/product-recommendation-main.component";
import { RecommendedProductNavigationComponent } from "./components/recommended-product-navigation/recommended-product-navigation.component";
import { NgLetModule } from "@ngrx-utils/store";

const components = [
  RecommendedProductItemComponent,
  ProductRecommendationPageComponent,
  RecommendedProductTabComponent,
  ProductRecommendationHeaderComponent,
  ProductRecommendationFooterComponent,
  ProductRecommendationMainComponent,
  RecommendedProductNavigationComponent,
];
@NgModule({
  declarations: [...components],
  imports: [CommonModule, NgLetModule],
  exports: [...components],
})
export class ProductRecommendationGlobalModule {}
