export enum QueryParameters {
  Portal = "portal",
  Payment = "payment",
}

export enum Portals {
  Kells = "kells",
  Aetna = "aetna",
  Beanstalk = "beanstalk",
  Lantnerdds = "lantnerdds",
  AYG = "ayg",
  DentalSavers = "dentalsavers",
  OralLink = "orallink",
}

export const portalsOrganizations = [
  {
    portal: Portals.Kells,
    organizationName: "Kells",
  },
  {
    portal: Portals.Aetna,
    organizationName: "Aetna",
  },
  {
    portal: Portals.Beanstalk,
    organizationName: "Beanstalk Benefits",
  },
  {
    portal: Portals.Lantnerdds,
    organizationName: "Lantnerdds",
  },
  {
    portal: Portals.AYG,
    organizationName: "Ayg",
  },
  {
    portal: Portals.DentalSavers,
    organizationName: "Dentalsavers",
  },
  {
    portal: Portals.OralLink,
    organizationName: "OralLink",
  },
];
