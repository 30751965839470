<div
  [ngClass]="{ 'report-product-recommendation': isInreportRecommendation }"
  class="product-recommendation"
>
  <div class="product-recommendation__header">PRODUCT RECOMMENDATION</div>
  <div class="product-recommendation__subheader">
    Our team of oral health experts has handpicked top-rated oral care products
    tailored to meet your needs.
  </div>
  <div class="product-recommendation__data">
    <kpr-product-recommendation-main
      class="product-recommendation__data__main"
    ></kpr-product-recommendation-main>
  </div>
</div>
