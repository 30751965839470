import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";

import { DataAccessService } from "@kells/apis/data-access";
import { observeProperty } from "@kells/utils/angular";
import { Observable } from "rxjs";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import { distinctUntilChanged } from "rxjs/operators";
import { getSessionScoreDate, RiskLevel } from "../../utils";

export const INITIAL_TO_MODERATE = [
  "#7b8ccc",
  "#918db7",
  "#a38ea1",
  "#b18f89",
  "#bb9175",
  "#c6925a",
  "#cf9440",
  "#d59620",
];
export const INITIAL_TO_ADVANCED = [
  "#7b8ccc",
  "#8a8ac2",
  "#9787b8",
  "#a285ae",
  "#ac82a5",
  "#b57f9b",
  "#bd7d91",
  "#c47a87",
  "#cb777e",
  "#d27474",
];
export const INITIAL_ONLY = [
  "#35557D",
  "#3A5B85",
  "#3F628E",
  "#456896",
  "#4B6F9E",
  "#5075A6",
  "#567CAF",
  "#5C83B7",
  "#6389BF",
  "#6990C7",
];

export const MODERATE_FROM_INITAL = ["#ddd1c8", "#f2d7a6"];
export const MODERATE_ONLY = [
  "#90561E",
  "#995F1F",
  "#A36820",
  "#AC7121",
  "#B57A23",
  "#BE8324",
  "#C78D25",
  "#CF9726",
  "#D7A127",
  "#DFAB28",
];

export const ADVANCED = [
  "#741413",
  "#831F1A",
  "#922A22",
  "#A0362B",
  "#AD4334",
  "#B9503F",
  "#C45E4A",
  "#CE6D56",
  "#D77C63",
  "#DF8B71",
];

@Component({
  selector: "koa-oral-condition-donut-chart",
  templateUrl: "./oral-condition-donut-chart.component.html",
  styleUrls: ["./oral-condition-donut-chart.component.scss"],
})
export class OralConditionDonutChartComponent implements OnDestroy, OnInit {
  @Input() score: number;

  private score$: Observable<number> = observeProperty(this, "score").pipe(
    keepDefined(),
    distinctUntilChanged()
  );

  @Input() reversed: boolean;

  chartLevel: RiskLevel | "" = "";
  chartScore = 0;

  hostWidth: string;
  hostHeight: string;

  private _subs = new SubSink();
  constructor(public data: DataAccessService) {}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  ngOnInit(): void {
    this._subs.sink = this.score$.subscribe((score) => {
      // Fix animation
      setTimeout(() => {
        this._initScoreData(score);
      });
    });
  }

  private _initScoreData(sessionScore: number) {
    const { statusLevel, chartScore } = getSessionScoreDate(sessionScore);

    this.chartLevel = statusLevel;
    this.chartScore = chartScore;
  }
}
