<kpr-recommended-product-navigation
  (tabChange)="tabChange($event)"
  [tabs]="tabs"
  class="product-navigation"
></kpr-recommended-product-navigation>

<div class="products-list">
  <ng-container *ngFor="let item of displayedRecommendedItems">
    <kpr-recommended-product-item
      [recommendedProduct]="item"
    ></kpr-recommended-product-item
  ></ng-container>
</div>
