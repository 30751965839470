<div class="product">
  <div class="product__image-container">
    <img
      [src]="recommendedProduct?.image"
      alt="Product"
      class="product__image"
    />
  </div>
  <div class="product__description">
    <div>
      <div class="product__title">
        {{ recommendedProduct?.name }}
      </div>

      <div class="product__subcategory">
        <div class="product__subcategory-title">Best For:</div>
        <div class="product__subcategory-list">
          {{ recommendedProduct?.subCategory?.join(", ") }}
        </div>
      </div>
    </div>

    <div class="product_links">
      <div>
        <div class="product__discount" *ngIf="recommendedProduct?.discount">
          <img src="assets/tag.svg" alt="tag" class="tag" />

          <span> {{ recommendedProduct?.discount }}% OFF </span>
        </div>
      </div>

      <a
        (click)="onShopNowClick()"
        [href]="recommendedProduct?.link"
        target="_blank"
        class="product__description__link"
        >BUY NOW</a
      >
    </div>
  </div>
</div>
