<div mat-dialog-title class="onboarding-dialog-title">
  <h2>Welcome to KELLS</h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/close.svg" alt="close" />
  </button>
</div>

<div class="onboarding-container-start" [ngClass]="{ hidden: isActivated }">
  <div class="onboarding-swiper-image-wrapper">
    <img
      [src]="'assets/onboarding-start.svg'"
      alt="starting image"
      class="onboarding-swiper-image-lg onboarding-starting-image"
    />
  </div>
  <div class="onboarding-swiper-title">
    This is your personal dental hub with unique capabilities to help you
    improve oral health. Below are the things you can do:
  </div>
</div>

<div class="onboarding-container">
  <swiper-container
    #swiper
    class="onboarding-swiper"
    init="false"
    space-between="20"
    [ngClass]="{ hidden: !isActivated }"
  >
    <swiper-slide
      class="onboarding-swiper-slide"
      *ngFor="let slide of ONBOARDING_SLIDES"
    >
      <div class="onboarding-swiper-image-wrapper">
        <img
          [src]="slide.image"
          [alt]="slide.header"
          class="onboarding-swiper-image-lg"
        />
      </div>
      <div class="onboarding-swiper-header">{{ slide.header }}</div>
      <div
        class="onboarding-swiper-title"
        *ngIf="currentSlideIndex !== 1; else secondSlideTitle"
      >
        {{ slide.title }}
      </div>
      <ng-template #secondSlideTitle>
        <div class="onboarding-swiper-title">
          Review KELLS report to understand your oral health status.<br />
          You can get one by using
          <span
            (click)="navigateToSlide(slideItemType.AIPhotoScan)"
            class="swiper-slide-link"
            >AI Dental Scan</span
          >
          <ng-container *ngIf="isTreatmentVerificationVisible$ | async">
            or
            <span
              (click)="navigateToSlide(slideItemType.TreatmentVerification)"
              class="swiper-slide-link"
              >Second Opinion</span
            > </ng-container
          >.
        </div>
      </ng-template>
    </swiper-slide>
  </swiper-container>
  <div class="swiper-button-container">
    <div
      class="swiper-button-prev"
      *ngIf="isPrevSlideVisible"
      (click)="onPrevSlide()"
    >
      Back
    </div>
    <div
      class="swiper-button-next"
      *ngIf="isNextSlideVisible"
      (click)="onNextSlide()"
    >
      Next
    </div>

    <div
      class="swiper-button-next"
      *ngIf="!isNextSlideVisible && !isPrevSlideVisible"
      (click)="finalize()"
    >
      Get Started
    </div>
  </div>
</div>
