import {
  CariesStageTypes,
  FindingStatus,
  FindingType,
} from "@kells/interfaces/finding";
import { TREATMENT_PLAN_TABLE_COLUMNS } from "../../../constants/treatment-plan-table-columns.constants";
import {
  ClassificationBaseKind,
  ImageOrderMode,
  ImageType,
} from "@kells/clinic-one/apis";

export const treatmentPlanTableColumns = TREATMENT_PLAN_TABLE_COLUMNS;

export const images = [
  {
    id: "6705eac70905113f99df46e5",
    url: "/assets/demo/intraoral/intraoral-1.jpeg",
    xrayCaptureTime: "2024-10-08T22:30:30.929000",
    orderMode: ImageOrderMode.NotInitialized,
    captureTime: null,
    xrayType: ClassificationBaseKind.NotInitialized,
    treatments: [
      "670633c3d5c7ecf5abc04619",
      "670633d3d5c7ecf5abc0461b",
      "670633e00905113f99df46e7",
      "670633fba480c01d8c7c6876",
      "67063407d5c7ecf5abc0461d",
      "6706340f0905113f99df46e9",
    ],
    findings: [
      {
        id: "670633c3d5c7ecf5abc04618",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [660, 444, 21, 30],
        stage: CariesStageTypes.Undefined,
        tooth: "21",
      },
      {
        id: "670633d3d5c7ecf5abc0461a",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [739, 441, 45, 31],
        stage: CariesStageTypes.Undefined,
        tooth: "19",
      },
      {
        id: "670633e00905113f99df46e6",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [781, 377, 25, 28],
        stage: CariesStageTypes.Undefined,
        tooth: "14",
      },
      {
        id: "670633fba480c01d8c7c6875",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [813, 377, 26, 32],
        stage: CariesStageTypes.Undefined,
        tooth: "15",
      },
      {
        id: "67063407d5c7ecf5abc0461c",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [430, 350, 44, 32],
        stage: CariesStageTypes.Undefined,
        tooth: "8",
      },
      {
        id: "6706340f0905113f99df46e8",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [409, 351, 21, 28],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    imageType: ImageType.Photo,
    status: FindingStatus.Confirmed,
    sessionId: "6705eab632ec40a73dd50f35",
  },
  {
    id: "6705eac6e51829d309ec613f",
    url: "/assets/demo/intraoral/intraoral-2.jpeg",
    orderMode: ImageOrderMode.NotInitialized,
    captureTime: null,
    xrayType: ClassificationBaseKind.NotInitialized,
    treatments: [],
    findings: [],
    imageType: ImageType.Photo,
    status: FindingStatus.Confirmed,
    sessionId: "6705eab632ec40a73dd50f35",
  },
  {
    id: "6705eac675e4d26ad78d4fe3",
    url: "/assets/demo/intraoral/intraoral-3.jpeg",
    xrayCaptureTime: "2024-10-08T22:30:30.080000",

    orderMode: ImageOrderMode.NotInitialized,
    captureTime: null,
    xrayType: ClassificationBaseKind.NotInitialized,
    treatments: ["670635b8d5c7ecf5abc0461f"],
    findings: [
      {
        id: "670635b8d5c7ecf5abc0461e",
        type: FindingType.Caries,
        status: FindingStatus.Confirmed,
        box: [350, 355, 13, 30],
        location: "Occlusal",
        stage: CariesStageTypes.Initial,
        tooth: "31",
      },
    ],
    imageType: ImageType.Photo,
    status: FindingStatus.Confirmed,
    sessionId: "6705eab632ec40a73dd50f35",
  },
  {
    id: "6705eac60905113f99df46e4",
    url: "/assets/demo/intraoral/intraoral-4.jpeg",
    xrayCaptureTime: "2024-10-08T22:30:29.681000",
    orderMode: ImageOrderMode.NotInitialized,
    captureTime: null,
    xrayType: ClassificationBaseKind.NotInitialized,
    treatments: [
      "670636a3d5c7ecf5abc04621",
      "670636f0d5c7ecf5abc04623",
      "67063707f54ce2a256845ee0",
    ],
    findings: [
      {
        id: "670636a3d5c7ecf5abc04620",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [663, 432, 52, 42],
        stage: CariesStageTypes.Undefined,
        tooth: "27",
      },
      {
        id: "670636f0d5c7ecf5abc04622",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [467, 364, 46, 31],
        stage: CariesStageTypes.Undefined,
        tooth: "3",
      },
      {
        id: "67063707f54ce2a256845edf",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [746, 343, 17, 22],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    imageType: ImageType.Photo,
    status: FindingStatus.Confirmed,
    sessionId: "6705eab632ec40a73dd50f35",
  },
  {
    id: "6705eac3e51829d309ec613e",
    url: "/assets/demo/intraoral/intraoral-5.jpeg",
    xrayCaptureTime: "2024-10-08T22:30:27.476000",
    orderMode: ImageOrderMode.NotInitialized,
    captureTime: null,
    xrayType: ClassificationBaseKind.NotInitialized,
    treatments: [
      "6706378a75e4d26ad78d4fe5",
      "670637980905113f99df46eb",
      "670637ab0905113f99df46ed",
    ],
    findings: [
      {
        id: "6706378a75e4d26ad78d4fe4",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [575, 399, 16, 22],
        stage: CariesStageTypes.Undefined,
        tooth: "24",
      },
      {
        id: "670637980905113f99df46ea",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [543, 404, 14, 20],
        stage: CariesStageTypes.Undefined,
        tooth: "25",
      },
      {
        id: "670637a4a480c01d8c7c6877",
        type: FindingType.Fracture,
        status: FindingStatus.Confirmed,
        box: [549, 381, 33, 14],
        stage: CariesStageTypes.Undefined,
        tooth: "8",
      },
      {
        id: "670637ab0905113f99df46ec",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [516, 341, 23, 24],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    imageType: ImageType.Photo,
    status: FindingStatus.Confirmed,
    sessionId: "6705eab632ec40a73dd50f35",
  },
];

export const treatments = [
  {
    description: "Monitor",
    id: "670635b8d5c7ecf5abc0461f",
    notes: undefined,
    patientId: "62fcf4ed4db515fea35d04f6",
    session: "6705eab632ec40a73dd50f35",
    sessionDate: undefined,
    tooth: "31",
  },
  {
    description: "Cleaning",
    id: "670637bea486650f7946ca6d",
    notes: undefined,
    patientId: "62fcf4ed4db515fea35d04f6",
    session: "6705eab632ec40a73dd50f35",
    sessionDate: undefined,
    tooth: "Whole Mouth",
  },
  {
    description: "Filling",
    id: "670637cfa480c01d8c7c6878",
    notes: undefined,
    patientId: "62fcf4ed4db515fea35d04f6",
    session: "6705eab632ec40a73dd50f35",
    sessionDate: undefined,
    tooth: "8",
  },
];

export const snapshot = {
  subhead:
    "In the Medium Risk category, photos reveal signs that may indicate the beginning stages of dental concerns, such as early decay and gum inflammation. While these issues might not require urgent treatment, they signal the need for improved oral hygiene practices, possibly alongside professional dental interventions. Regular monitoring and a visit to the dentist are recommended to prevent progression into more serious conditions.",
};

export const sessionScore = 4;

export const findingsByImage = [
  {
    name: "2024-10-8_1",
    session: "2024-10-8",
    sessionId: "6705eab632ec40a73dd50f35",
    image: {
      id: "6705eac70905113f99df46e5",
      url: "/assets/demo/intraoral/intraoral-1.jpeg",
      xrayCaptureTime: "2024-10-08T22:30:30.929000",
      orderMode: ImageOrderMode.NotInitialized,
      captureTime: null,
      xrayType: ClassificationBaseKind.NotInitialized,
      treatments: [
        "670633c3d5c7ecf5abc04619",
        "670633d3d5c7ecf5abc0461b",
        "670633e00905113f99df46e7",
        "670633fba480c01d8c7c6876",
        "67063407d5c7ecf5abc0461d",
        "6706340f0905113f99df46e9",
      ],
      findings: [
        {
          id: "670633c3d5c7ecf5abc04618",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [660, 444, 21, 30],
          stage: CariesStageTypes.Undefined,
          tooth: "21",
        },
        {
          id: "670633d3d5c7ecf5abc0461a",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [739, 441, 45, 31],
          stage: CariesStageTypes.Undefined,
          tooth: "19",
        },
        {
          id: "670633e00905113f99df46e6",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [781, 377, 25, 28],
          stage: CariesStageTypes.Undefined,
          tooth: "14",
        },
        {
          id: "670633fba480c01d8c7c6875",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [813, 377, 26, 32],
          stage: CariesStageTypes.Undefined,
          tooth: "15",
        },
        {
          id: "67063407d5c7ecf5abc0461c",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [430, 350, 44, 32],
          stage: CariesStageTypes.Undefined,
          tooth: "8",
        },
        {
          id: "6706340f0905113f99df46e8",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [409, 351, 21, 28],
          stage: CariesStageTypes.Undefined,
          tooth: "7",
        },
      ],
      imageType: ImageType.Photo,
      status: FindingStatus.Confirmed,
      sessionId: "6705eab632ec40a73dd50f35",
    },
    image_id: "6705eac70905113f99df46e5",
    image_url: "/assets/demo/intraoral/intraoral-1.jpeg",
    imageAppearances: 1,
    allFindings: [
      {
        id: "670633c3d5c7ecf5abc04618",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [660, 444, 21, 30],
        stage: CariesStageTypes.Undefined,
        tooth: "21",
      },
      {
        id: "670633d3d5c7ecf5abc0461a",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [739, 441, 45, 31],
        stage: CariesStageTypes.Undefined,
        tooth: "19",
      },
      {
        id: "670633e00905113f99df46e6",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [781, 377, 25, 28],
        stage: CariesStageTypes.Undefined,
        tooth: "14",
      },
      {
        id: "670633fba480c01d8c7c6875",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [813, 377, 26, 32],
        stage: CariesStageTypes.Undefined,
        tooth: "15",
      },
      {
        id: "67063407d5c7ecf5abc0461c",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [430, 350, 44, 32],
        stage: CariesStageTypes.Undefined,
        tooth: "8",
      },
      {
        id: "6706340f0905113f99df46e8",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [409, 351, 21, 28],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    totalCaries: 0,
    qtyAdvanced: 0,
    qtyInitial: 0,
    qtyModerate: 0,
    qtyMaterial: 0,
    qtyBoneLoss: 0,
    images: [
      {
        id: "6705eac70905113f99df46e5",
        url: "/assets/demo/intraoral/intraoral-1.jpeg",
        xrayCaptureTime: "2024-10-08T22:30:30.929000",
        orderMode: ImageOrderMode.NotInitialized,
        captureTime: null,
        xrayType: ClassificationBaseKind.NotInitialized,
        treatments: [
          "670633c3d5c7ecf5abc04619",
          "670633d3d5c7ecf5abc0461b",
          "670633e00905113f99df46e7",
          "670633fba480c01d8c7c6876",
          "67063407d5c7ecf5abc0461d",
          "6706340f0905113f99df46e9",
        ],
        findings: [
          {
            id: "670633c3d5c7ecf5abc04618",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [660, 444, 21, 30],
            stage: CariesStageTypes.Undefined,
            tooth: "21",
          },
          {
            id: "670633d3d5c7ecf5abc0461a",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [739, 441, 45, 31],
            stage: CariesStageTypes.Undefined,
            tooth: "19",
          },
          {
            id: "670633e00905113f99df46e6",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [781, 377, 25, 28],
            stage: CariesStageTypes.Undefined,
            tooth: "14",
          },
          {
            id: "670633fba480c01d8c7c6875",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [813, 377, 26, 32],
            stage: CariesStageTypes.Undefined,
            tooth: "15",
          },
          {
            id: "67063407d5c7ecf5abc0461c",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [430, 350, 44, 32],
            stage: CariesStageTypes.Undefined,
            tooth: "8",
          },
          {
            id: "6706340f0905113f99df46e8",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [409, 351, 21, 28],
            stage: CariesStageTypes.Undefined,
            tooth: "7",
          },
        ],
        imageType: ImageType.Photo,
        status: FindingStatus.Confirmed,
        sessionId: "6705eab632ec40a73dd50f35",
      },
    ],
    xrays: [],
    allCaries: [],
    treatments: [],
    tooth: [],
    initial: [],
    advanced: [],
    moderate: [],
    material: [],
    boneLoss: [],
    calculus: [],
    fracture: [],
    infection: [],
    defectiveRestoration: [],
    plaque: [
      {
        id: "670633c3d5c7ecf5abc04618",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [660, 444, 21, 30],
        stage: CariesStageTypes.Undefined,
        tooth: "21",
      },
      {
        id: "670633d3d5c7ecf5abc0461a",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [739, 441, 45, 31],
        stage: CariesStageTypes.Undefined,
        tooth: "19",
      },
      {
        id: "670633e00905113f99df46e6",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [781, 377, 25, 28],
        stage: CariesStageTypes.Undefined,
        tooth: "14",
      },
      {
        id: "670633fba480c01d8c7c6875",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [813, 377, 26, 32],
        stage: CariesStageTypes.Undefined,
        tooth: "15",
      },
      {
        id: "67063407d5c7ecf5abc0461c",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [430, 350, 44, 32],
        stage: CariesStageTypes.Undefined,
        tooth: "8",
      },
      {
        id: "6706340f0905113f99df46e8",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [409, 351, 21, 28],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    gumRecession: [],
    gumInflammation: [],
    missingTooth: [],
    imageType: ImageType.Photo,
  },
  {
    name: "2024-10-8_2",
    session: "2024-10-8",
    sessionId: "6705eab632ec40a73dd50f35",
    image: {
      id: "6705eac6e51829d309ec613f",
      url: "/assets/demo/intraoral/intraoral-2.jpeg",
      orderMode: ImageOrderMode.NotInitialized,
      captureTime: null,
      xrayType: ClassificationBaseKind.NotInitialized,
      treatments: [],
      findings: [],
      imageType: ImageType.Photo,
      status: FindingStatus.Confirmed,
      sessionId: "6705eab632ec40a73dd50f35",
    },
    image_id: "6705eac6e51829d309ec613f",
    image_url: "/assets/demo/intraoral/intraoral-2.jpeg",
    imageAppearances: 1,
    allFindings: [],
    totalCaries: 0,
    qtyAdvanced: 0,
    qtyInitial: 0,
    qtyModerate: 0,
    qtyMaterial: 0,
    qtyBoneLoss: 0,
    images: [
      {
        id: "6705eac6e51829d309ec613f",
        url: "/assets/demo/intraoral/intraoral-2.jpeg",
        orderMode: ImageOrderMode.NotInitialized,
        captureTime: null,
        xrayType: ClassificationBaseKind.NotInitialized,
        treatments: [],
        findings: [],
        imageType: ImageType.Photo,
        status: FindingStatus.Confirmed,
        sessionId: "6705eab632ec40a73dd50f35",
      },
    ],
    xrays: [],
    allCaries: [],
    treatments: [],
    tooth: [],
    initial: [],
    advanced: [],
    moderate: [],
    material: [],
    boneLoss: [],
    calculus: [],
    fracture: [],
    infection: [],
    defectiveRestoration: [],
    plaque: [],
    gumRecession: [],
    gumInflammation: [],
    missingTooth: [],
    imageType: ImageType.Photo,
  },
  {
    name: "2024-10-8_3",
    session: "2024-10-8",
    sessionId: "6705eab632ec40a73dd50f35",
    image: {
      id: "6705eac675e4d26ad78d4fe3",
      url: "/assets/demo/intraoral/intraoral-3.jpeg",
      xrayCaptureTime: "2024-10-08T22:30:30.080000",
      orderMode: ImageOrderMode.NotInitialized,
      captureTime: null,
      xrayType: ClassificationBaseKind.NotInitialized,
      treatments: ["670635b8d5c7ecf5abc0461f"],
      findings: [
        {
          id: "670635b8d5c7ecf5abc0461e",
          type: FindingType.Caries,
          status: FindingStatus.Confirmed,
          box: [350, 355, 13, 30],
          location: "Occlusal",
          stage: CariesStageTypes.Initial,
          tooth: "31",
        },
      ],
      imageType: ImageType.Photo,
      status: FindingStatus.Confirmed,
      sessionId: "6705eab632ec40a73dd50f35",
    },
    image_id: "6705eac675e4d26ad78d4fe3",
    image_url: "/assets/demo/intraoral/intraoral-3.jpeg",
    imageAppearances: 1,
    allFindings: [
      {
        id: "670635b8d5c7ecf5abc0461e",
        type: FindingType.Caries,
        status: FindingStatus.Confirmed,
        box: [350, 355, 13, 30],
        location: "Occlusal",
        stage: CariesStageTypes.Initial,
        tooth: "31",
      },
    ],
    totalCaries: 0,
    qtyAdvanced: 0,
    qtyInitial: 0,
    qtyModerate: 0,
    qtyMaterial: 0,
    qtyBoneLoss: 0,
    images: [
      {
        id: "6705eac675e4d26ad78d4fe3",
        url: "/assets/demo/intraoral/intraoral-3.jpeg",
        xrayCaptureTime: "2024-10-08T22:30:30.080000",
        orderMode: ImageOrderMode.NotInitialized,
        captureTime: null,
        xrayType: ClassificationBaseKind.NotInitialized,
        treatments: ["670635b8d5c7ecf5abc0461f"],
        findings: [
          {
            id: "670635b8d5c7ecf5abc0461e",
            type: FindingType.Caries,
            status: FindingStatus.Confirmed,
            box: [350, 355, 13, 30],
            location: "Occlusal",
            stage: CariesStageTypes.Initial,
            tooth: "31",
          },
        ],
        imageType: ImageType.Photo,
        status: FindingStatus.Confirmed,
        sessionId: "6705eab632ec40a73dd50f35",
      },
    ],
    xrays: [],
    allCaries: [
      {
        id: "670635b8d5c7ecf5abc0461e",
        type: FindingType.Caries,
        status: FindingStatus.Confirmed,
        box: [350, 355, 13, 30],
        location: "Occlusal",
        stage: CariesStageTypes.Initial,
        tooth: "31",
      },
    ],
    treatments: [],
    tooth: [],
    initial: [
      {
        id: "670635b8d5c7ecf5abc0461e",
        type: FindingType.Caries,
        status: FindingStatus.Confirmed,
        box: [350, 355, 13, 30],
        location: "Occlusal",
        stage: CariesStageTypes.Initial,
        tooth: "31",
      },
    ],
    advanced: [],
    moderate: [],
    material: [],
    boneLoss: [],
    calculus: [],
    fracture: [],
    infection: [],
    defectiveRestoration: [],
    plaque: [],
    gumRecession: [],
    gumInflammation: [],
    missingTooth: [],
    imageType: ImageType.Photo,
  },
  {
    name: "2024-10-8_4",
    session: "2024-10-8",
    sessionId: "6705eab632ec40a73dd50f35",
    image: {
      id: "6705eac60905113f99df46e4",
      url: "/assets/demo/intraoral/intraoral-4.jpeg",
      xrayCaptureTime: "2024-10-08T22:30:29.681000",
      orderMode: ImageOrderMode.NotInitialized,
      captureTime: null,
      xrayType: ClassificationBaseKind.NotInitialized,
      treatments: [
        "670636a3d5c7ecf5abc04621",
        "670636f0d5c7ecf5abc04623",
        "67063707f54ce2a256845ee0",
      ],
      findings: [
        {
          id: "670636a3d5c7ecf5abc04620",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [663, 432, 52, 42],
          stage: CariesStageTypes.Undefined,
          tooth: "27",
        },
        {
          id: "670636f0d5c7ecf5abc04622",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [467, 364, 46, 31],
          stage: CariesStageTypes.Undefined,
          tooth: "3",
        },
        {
          id: "67063707f54ce2a256845edf",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [746, 343, 17, 22],
          stage: CariesStageTypes.Undefined,
          tooth: "7",
        },
      ],
      imageType: ImageType.Photo,
      status: FindingStatus.Confirmed,
      sessionId: "6705eab632ec40a73dd50f35",
    },
    image_id: "6705eac60905113f99df46e4",
    image_url: "/assets/demo/intraoral/intraoral-4.jpeg",
    imageAppearances: 1,
    allFindings: [
      {
        id: "670636a3d5c7ecf5abc04620",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [663, 432, 52, 42],
        stage: CariesStageTypes.Undefined,
        tooth: "27",
      },
      {
        id: "670636f0d5c7ecf5abc04622",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [467, 364, 46, 31],
        stage: CariesStageTypes.Undefined,
        tooth: "3",
      },
      {
        id: "67063707f54ce2a256845edf",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [746, 343, 17, 22],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    totalCaries: 0,
    qtyAdvanced: 0,
    qtyInitial: 0,
    qtyModerate: 0,
    qtyMaterial: 0,
    qtyBoneLoss: 0,
    images: [
      {
        id: "6705eac60905113f99df46e4",
        url: "/assets/demo/intraoral/intraoral-4.jpeg",
        xrayCaptureTime: "2024-10-08T22:30:29.681000",
        orderMode: ImageOrderMode.NotInitialized,
        captureTime: null,
        xrayType: ClassificationBaseKind.NotInitialized,
        treatments: [
          "670636a3d5c7ecf5abc04621",
          "670636f0d5c7ecf5abc04623",
          "67063707f54ce2a256845ee0",
        ],
        findings: [
          {
            id: "670636a3d5c7ecf5abc04620",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [663, 432, 52, 42],
            stage: CariesStageTypes.Undefined,
            tooth: "27",
          },
          {
            id: "670636f0d5c7ecf5abc04622",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [467, 364, 46, 31],
            stage: CariesStageTypes.Undefined,
            tooth: "3",
          },
          {
            id: "67063707f54ce2a256845edf",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [746, 343, 17, 22],
            stage: CariesStageTypes.Undefined,
            tooth: "7",
          },
        ],
        imageType: ImageType.Photo,
        status: FindingStatus.Confirmed,
        sessionId: "6705eab632ec40a73dd50f35",
      },
    ],
    xrays: [],
    allCaries: [],
    treatments: [],
    tooth: [],
    initial: [],
    advanced: [],
    moderate: [],
    material: [],
    boneLoss: [],
    calculus: [],
    fracture: [],
    infection: [],
    defectiveRestoration: [],
    plaque: [
      {
        id: "670636a3d5c7ecf5abc04620",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [663, 432, 52, 42],
        stage: CariesStageTypes.Undefined,
        tooth: "27",
      },
      {
        id: "670636f0d5c7ecf5abc04622",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [467, 364, 46, 31],
        stage: CariesStageTypes.Undefined,
        tooth: "3",
      },
      {
        id: "67063707f54ce2a256845edf",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [746, 343, 17, 22],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    gumRecession: [],
    gumInflammation: [],
    missingTooth: [],
    imageType: ImageType.Photo,
  },
  {
    name: "2024-10-8_5",
    session: "2024-10-8",
    sessionId: "6705eab632ec40a73dd50f35",
    image: {
      id: "6705eac3e51829d309ec613e",
      url: "/assets/demo/intraoral/intraoral-5.jpeg",
      xrayCaptureTime: "2024-10-08T22:30:27.476000",
      orderMode: ImageOrderMode.NotInitialized,
      captureTime: null,
      xrayType: ClassificationBaseKind.NotInitialized,
      treatments: [
        "6706378a75e4d26ad78d4fe5",
        "670637980905113f99df46eb",
        "670637ab0905113f99df46ed",
      ],
      findings: [
        {
          id: "6706378a75e4d26ad78d4fe4",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [575, 399, 16, 22],
          stage: CariesStageTypes.Undefined,
          tooth: "24",
        },
        {
          id: "670637980905113f99df46ea",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [543, 404, 14, 20],
          stage: CariesStageTypes.Undefined,
          tooth: "25",
        },
        {
          id: "670637a4a480c01d8c7c6877",
          type: FindingType.Fracture,
          status: FindingStatus.Confirmed,
          box: [549, 381, 33, 14],
          stage: CariesStageTypes.Undefined,
          tooth: "8",
        },
        {
          id: "670637ab0905113f99df46ec",
          type: FindingType.Plaque,
          status: FindingStatus.Confirmed,
          box: [516, 341, 23, 24],
          stage: CariesStageTypes.Undefined,
          tooth: "7",
        },
      ],
      imageType: ImageType.Photo,
      status: FindingStatus.Confirmed,
      sessionId: "6705eab632ec40a73dd50f35",
    },
    image_id: "6705eac3e51829d309ec613e",
    image_url: "/assets/demo/intraoral/intraoral-5.jpeg",
    imageAppearances: 1,
    allFindings: [
      {
        id: "6706378a75e4d26ad78d4fe4",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [575, 399, 16, 22],
        stage: CariesStageTypes.Undefined,
        tooth: "24",
      },
      {
        id: "670637980905113f99df46ea",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [543, 404, 14, 20],
        stage: CariesStageTypes.Undefined,
        tooth: "25",
      },
      {
        id: "670637a4a480c01d8c7c6877",
        type: FindingType.Fracture,
        status: FindingStatus.Confirmed,
        box: [549, 381, 33, 14],
        stage: CariesStageTypes.Undefined,
        tooth: "8",
      },
      {
        id: "670637ab0905113f99df46ec",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [516, 341, 23, 24],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    totalCaries: 0,
    qtyAdvanced: 0,
    qtyInitial: 0,
    qtyModerate: 0,
    qtyMaterial: 0,
    qtyBoneLoss: 0,
    images: [
      {
        id: "6705eac3e51829d309ec613e",
        url: "/assets/demo/intraoral/intraoral-5.jpeg",
        xrayCaptureTime: "2024-10-08T22:30:27.476000",
        orderMode: ImageOrderMode.NotInitialized,
        captureTime: null,
        xrayType: ClassificationBaseKind.NotInitialized,
        treatments: [
          "6706378a75e4d26ad78d4fe5",
          "670637980905113f99df46eb",
          "670637ab0905113f99df46ed",
        ],
        findings: [
          {
            id: "6706378a75e4d26ad78d4fe4",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [575, 399, 16, 22],
            stage: CariesStageTypes.Undefined,
            tooth: "24",
          },
          {
            id: "670637980905113f99df46ea",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [543, 404, 14, 20],
            stage: CariesStageTypes.Undefined,
            tooth: "25",
          },
          {
            id: "670637a4a480c01d8c7c6877",
            type: FindingType.Fracture,
            status: FindingStatus.Confirmed,
            box: [549, 381, 33, 14],
            stage: CariesStageTypes.Undefined,
            tooth: "8",
          },
          {
            id: "670637ab0905113f99df46ec",
            type: FindingType.Plaque,
            status: FindingStatus.Confirmed,
            box: [516, 341, 23, 24],
            stage: CariesStageTypes.Undefined,
            tooth: "7",
          },
        ],
        imageType: ImageType.Photo,
        status: FindingStatus.Confirmed,
        sessionId: "6705eab632ec40a73dd50f35",
      },
    ],
    xrays: [],
    allCaries: [],
    treatments: [],
    tooth: [],
    initial: [],
    advanced: [],
    moderate: [],
    material: [],
    boneLoss: [],
    calculus: [],
    fracture: [
      {
        id: "670637a4a480c01d8c7c6877",
        type: FindingType.Fracture,
        status: FindingStatus.Confirmed,
        box: [549, 381, 33, 14],
        stage: CariesStageTypes.Undefined,
        tooth: "8",
      },
    ],
    infection: [],
    defectiveRestoration: [],
    plaque: [
      {
        id: "6706378a75e4d26ad78d4fe4",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [575, 399, 16, 22],
        stage: CariesStageTypes.Undefined,
        tooth: "24",
      },
      {
        id: "670637980905113f99df46ea",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [543, 404, 14, 20],
        stage: CariesStageTypes.Undefined,
        tooth: "25",
      },
      {
        id: "670637ab0905113f99df46ec",
        type: FindingType.Plaque,
        status: FindingStatus.Confirmed,
        box: [516, 341, 23, 24],
        stage: CariesStageTypes.Undefined,
        tooth: "7",
      },
    ],
    gumRecession: [],
    gumInflammation: [],
    missingTooth: [],
    imageType: ImageType.Photo,
  },
];

export const findingsByTooth = new Map([
  [
    "21",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "21",
      name: "1st Premolar",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670633c3d5c7ecf5abc04618",
          type: "plaque",
          status: "confirmed",
          box: [660, 444, 21, 30],
          stage: "",
          tooth: "21",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-1.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "670633c3d5c7ecf5abc04618",
          type: "plaque",
          status: "confirmed",
          box: [660, 444, 21, 30],
          stage: "",
          tooth: "21",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "19",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "19",
      name: "1st Molar",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670633d3d5c7ecf5abc0461a",
          type: "plaque",
          status: "confirmed",
          box: [739, 441, 45, 31],
          stage: "",
          tooth: "19",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-1.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "670633d3d5c7ecf5abc0461a",
          type: "plaque",
          status: "confirmed",
          box: [739, 441, 45, 31],
          stage: "",
          tooth: "19",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "14",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "14",
      name: "1st Molar",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670633e00905113f99df46e6",
          type: "plaque",
          status: "confirmed",
          box: [781, 377, 25, 28],
          stage: "",
          tooth: "14",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-1.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "670633e00905113f99df46e6",
          type: "plaque",
          status: "confirmed",
          box: [781, 377, 25, 28],
          stage: "",
          tooth: "14",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "15",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "15",
      name: "2nd Molar",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670633fba480c01d8c7c6875",
          type: "plaque",
          status: "confirmed",
          box: [813, 377, 26, 32],
          stage: "",
          tooth: "15",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-1.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "670633fba480c01d8c7c6875",
          type: "plaque",
          status: "confirmed",
          box: [813, 377, 26, 32],
          stage: "",
          tooth: "15",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "8",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "8",
      name: "Central incisor",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "67063407d5c7ecf5abc0461c",
          type: "plaque",
          status: "confirmed",
          box: [430, 350, 44, 32],
          stage: "",
          tooth: "8",
        },
        {
          id: "670637a4a480c01d8c7c6877",
          type: "fracture",
          status: "confirmed",
          box: [549, 381, 33, 14],
          stage: "",
          tooth: "8",
        },
      ],
      xrays: [
        "/assets/demo/intraoral/intraoral-1.jpeg",
        "/assets/demo/intraoral/intraoral-5.jpeg",
      ],
      images: [],
      allCaries: [],
      treatments: [
        {
          id: "670637cfa480c01d8c7c6878",
          patientId: "62fcf4ed4db515fea35d04f6",
          session: "6705eab632ec40a73dd50f35",
          description: "Filling",
          tooth: "8",
        },
      ],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [
        {
          id: "670637a4a480c01d8c7c6877",
          type: "fracture",
          status: "confirmed",
          box: [549, 381, 33, 14],
          stage: "",
          tooth: "8",
        },
      ],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "67063407d5c7ecf5abc0461c",
          type: "plaque",
          status: "confirmed",
          box: [430, 350, 44, 32],
          stage: "",
          tooth: "8",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "7",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "7",
      name: "Lateral incisor",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "6706340f0905113f99df46e8",
          type: "plaque",
          status: "confirmed",
          box: [409, 351, 21, 28],
          stage: "",
          tooth: "7",
        },
        {
          id: "67063707f54ce2a256845edf",
          type: "plaque",
          status: "confirmed",
          box: [746, 343, 17, 22],
          stage: "",
          tooth: "7",
        },
        {
          id: "670637ab0905113f99df46ec",
          type: "plaque",
          status: "confirmed",
          box: [516, 341, 23, 24],
          stage: "",
          tooth: "7",
        },
      ],
      xrays: [
        "/assets/demo/intraoral/intraoral-1.jpeg",
        "/assets/demo/intraoral/intraoral-4.jpeg",
        "/assets/demo/intraoral/intraoral-5.jpeg",
      ],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "6706340f0905113f99df46e8",
          type: "plaque",
          status: "confirmed",
          box: [409, 351, 21, 28],
          stage: "",
          tooth: "7",
        },
        {
          id: "67063707f54ce2a256845edf",
          type: "plaque",
          status: "confirmed",
          box: [746, 343, 17, 22],
          stage: "",
          tooth: "7",
        },
        {
          id: "670637ab0905113f99df46ec",
          type: "plaque",
          status: "confirmed",
          box: [516, 341, 23, 24],
          stage: "",
          tooth: "7",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "31",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "31",
      name: "2nd Molar",
      toothAppearances: 0,
      totalCaries: 1,
      qtyAdvanced: 0,
      qtyInitial: 1,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670635b8d5c7ecf5abc0461e",
          type: "caries",
          status: "confirmed",
          box: [350, 355, 13, 30],
          location: "Occlusal",
          stage: "Initial",
          tooth: "31",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-3.jpeg"],
      images: [],
      allCaries: [
        {
          id: "670635b8d5c7ecf5abc0461e",
          type: "caries",
          status: "confirmed",
          box: [350, 355, 13, 30],
          location: "Occlusal",
          stage: "Initial",
          tooth: "31",
        },
      ],
      treatments: [
        {
          id: "670635b8d5c7ecf5abc0461f",
          patientId: "62fcf4ed4db515fea35d04f6",
          session: "6705eab632ec40a73dd50f35",
          description: "Monitor",
          tooth: "31",
        },
      ],
      tooth: [],
      initial: [
        {
          id: "670635b8d5c7ecf5abc0461e",
          type: "caries",
          status: "confirmed",
          box: [350, 355, 13, 30],
          location: "Occlusal",
          stage: "Initial",
          tooth: "31",
        },
      ],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "27",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "27",
      name: "Cuspid or canine",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670636a3d5c7ecf5abc04620",
          type: "plaque",
          status: "confirmed",
          box: [663, 432, 52, 42],
          stage: "",
          tooth: "27",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-4.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "670636a3d5c7ecf5abc04620",
          type: "plaque",
          status: "confirmed",
          box: [663, 432, 52, 42],
          stage: "",
          tooth: "27",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "3",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "3",
      name: "1st Molar",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670636f0d5c7ecf5abc04622",
          type: "plaque",
          status: "confirmed",
          box: [467, 364, 46, 31],
          stage: "",
          tooth: "3",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-4.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "670636f0d5c7ecf5abc04622",
          type: "plaque",
          status: "confirmed",
          box: [467, 364, 46, 31],
          stage: "",
          tooth: "3",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "24",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "24",
      name: "Central incisor",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "6706378a75e4d26ad78d4fe4",
          type: "plaque",
          status: "confirmed",
          box: [575, 399, 16, 22],
          stage: "",
          tooth: "24",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-5.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "6706378a75e4d26ad78d4fe4",
          type: "plaque",
          status: "confirmed",
          box: [575, 399, 16, 22],
          stage: "",
          tooth: "24",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
  [
    "25",
    {
      session: "2024-10-8",
      sessionId: "6705eab632ec40a73dd50f35",
      tooth_id: "25",
      name: "Central incisor",
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [
        {
          id: "670637980905113f99df46ea",
          type: "plaque",
          status: "confirmed",
          box: [543, 404, 14, 20],
          stage: "",
          tooth: "25",
        },
      ],
      xrays: ["/assets/demo/intraoral/intraoral-5.jpeg"],
      images: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [
        {
          id: "670637980905113f99df46ea",
          type: "plaque",
          status: "confirmed",
          box: [543, 404, 14, 20],
          stage: "",
          tooth: "25",
        },
      ],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    },
  ],
]);
