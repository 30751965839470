import { Component, Input } from "@angular/core";

@Component({
  selector: "kpr-product-recommendation-page",
  templateUrl: "./product-recommendation-page.component.html",
  styleUrls: ["./product-recommendation-page.component.scss"],
})
export class ProductRecommendationPageComponent {
  @Input() public isInreportRecommendation: boolean;

  constructor() {}
}
